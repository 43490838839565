<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-7">
                <h5 class="mt-2">Reporte Novedades</h5>
              </div>
              <div class="col-sm-5">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Hidrocarburos">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">HSE</li>
                  <li class="breadcrumb-item active">Reporte Novedades</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="empresa"
                        placeholder="Razon social"
                        label="razon_social"
                        :options="listasForms.empresas"
                        @input="seleccionarEmpresa()"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label select-label"
                        >Tipo Novedad</label
                      >
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_novedad"
                        @change="getTiposSubnovedades(filtros.tipo_novedad)"
                      >
                        <option
                          :value="tn.numeracion"
                          v-for="tn in listasForms.tiposNovedades"
                          :key="tn.numeracion"
                        >
                          {{ tn.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label select-label">Sub Novedad</label>
                      <v-select
                        multiple
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        @input="selectSubNovedades()"
                        placeholder="Novedades"
                        class="form-control form-control-sm p-0"
                        v-model="subNovedades"
                        label="descripcion"
                        :options="listasForms.subNovedades"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div
                    class="col-md-1"
                    v-if="listasForms.relevancias.length > 0"
                  >
                    <div class="form-group">
                      <label class="form-label select-label">Relevancia</label>
                      <select
                        v-model="filtros.relevancia"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.relevancias"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          :value="relevancia.numeracion"
                          v-for="relevancia in listasForms.relevancias"
                          :key="relevancia.numeracion"
                        >
                          {{ relevancia.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label>Vehiculo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.vehiculo"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Conductor</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Documento"
                        v-model="filtros.conductor"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Guia</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.guia"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Origen</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="origen"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="selectOrigenSitio()"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Destino</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="destino"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="selectDestinoSitio()"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label>Viaje</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.viaje"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 bg-light">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Fecha ini guia</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_ini_guia"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Fecha fin guia</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin_guia"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 bg-secondary">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Fecha ini novedad</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_ini_novedad"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Fecha fin novedad</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin_novedad"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-primary mt-2" @click="buscar()">
                      <i class="fas fa-search"></i><br />Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" style="display: inherit">
              <div class="card-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Tabla"
                      data-toggle="tab"
                      href="#Tabla"
                      @click="setVista('reporteNovedadeshseTabla')"
                      >Tabla</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Mapa"
                      data-toggle="tab"
                      href="#Mapa"
                      @click="setVista('reporteNovedadeshseMapa')"
                      >Mapa</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Grafica"
                      data-toggle="tab"
                      href="#Grafica"
                      >Gráfica</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Dinamico"
                      data-toggle="tab"
                      href="#Dinamico"
                      @click="setVista('reporteNovedadesHSEDinamico')"
                      >Dinamico</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Tabla">
                    <ReporteNovedadeshseTabla
                      ref="reporteNovedadeshseTabla"
                      v-show="vista == 'reporteNovedadeshseTabla'"
                    ></ReporteNovedadeshseTabla>
                  </div>
                  <div class="tab-pane fade" id="Mapa">
                    <ReporteNovedadeshseMapa
                      ref="reporteNovedadeshseMapa"
                      v-show="vista == 'reporteNovedadeshseMapa'"
                    ></ReporteNovedadeshseMapa>
                  </div>
                  <div class="tab-pane fade" id="Grafica"></div>
                  <div class="tab-pane fade active show" id="Dinamico">
                    <ReporteNovedadesHSEDinamico
                      ref="reporteNovedadesHSEDinamico"
                    ></ReporteNovedadesHSEDinamico>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ViajesDetViaje ref="ViajesDetViaje" />
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import ReporteNovedadeshseTabla from "./ReporteNovedadesHSETabla";
import ReporteNovedadeshseMapa from "./ReporteNovedadesHSEMapa";
import ReporteNovedadesHSEDinamico from "./ReporteNovedadesHSEDinamico";
import ViajesDetViaje from "../viajes/ViajesDetViaje";
import vSelect from "vue-select";
import axios from "axios";
export default {
  name: "ReporteNovedadeshseindex",
  components: {
    Loading,
    vSelect,
    ReporteNovedadeshseTabla,
    ReporteNovedadeshseMapa,
    ReporteNovedadesHSEDinamico,
    ViajesDetViaje,
  },
  data() {
    return {
      cargando: false,
      empresa: null,
      origen: null,
      destino: null,
      subNovedades: null,
      filtros: {
        empresa_id: null,
        tipo_novedad: null,
        vehiculo: null,
        conductor: null,
        origen_id: null,
        destino_id: null,
        guia: null,
        viaje: null,
        fecha_ini_guia: null,
        fecha_fin_guia: null,
        fecha_ini_novedad: null,
        fecha_fin_novedad: null,
        subnovedades: null,
      },
      listasForms: {
        empresas: [],
        sitios: [],
        relevancias: [],
        tiposNovedades: [],
        subNovedades: [],
      },
      vista: "reporteNovedadeshseTabla",
    };
  },
  methods: {
    llenarModalDetViaje(viaje, tipo_control) {
      this.$refs.ViajesDetViaje.llenar_modal_detViaje(viaje, tipo_control);
    },

    async buscar() {
      if (this.vista == "reporteNovedadeshseTabla") {
        await this.$refs.reporteNovedadeshseTabla.getNovedades();
      }
      if (this.vista == "reporteNovedadeshseMapa") {
        await this.$refs.reporteNovedadeshseMapa.getNovedades();
      }
      if (this.vista == "reporteNovedadesHSEDinamico") {
        await this.$refs.reporteNovedadesHSEDinamico.generarPivotTable();
      }
    },

    async setVista(vista) {
      this.vista = await vista;
      await this.buscar();
    },

    buscarEmpresas() {
      let me = this;
      const url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getRelevancias() {
      axios.get("/api/lista/149").then((response) => {
        this.listasForms.relevancias = response.data;
      });
    },

    getTiposNovedades() {
      axios.get("/api/lista/157").then((response) => {
        this.listasForms.tiposNovedades = response.data;
      });
    },

    async getTiposSubnovedades(tipoNovedad) {
      if (tipoNovedad == 1) {
        await axios
          .get("/api/lista/158", {
            params: { in_number: [7, 8, 9, 10, 11, 12] },
          })
          .then((response) => {
            this.listasForms.subNovedades = response.data;
          });

        /* this.listasForms.subNovedades = [
          { nombre: "Exceso de velocidad", id: 5 },
          { nombre: "Aceleración Brusca", id: 7 },
          { nombre: "Frenada Brusca", id: 8 },
          { nombre: "Curva Brusca", id: 9 },
          { nombre: "Maltrato Suspension", id: 10 },
          { nombre: "Alerta Impacto", id: 11 },
          { nombre: "Botón Emergencia", id: 12 },
        ]; */
      }
      if (tipoNovedad == 2) {
        await axios.get("/api/lista/159").then((response) => {
          this.listasForms.subNovedades = response.data;
        });

        /* this.listasForms.subNovedades = [
          { nombre: "Salida de ruta", id: 1 },
          { nombre: "Exceso de velocidad", id: 5 },
        ]; */
      }
    },

    selectSubNovedades() {
      let ids_nov = [];
      for (let i = 0; i < this.subNovedades.length; i++) {
        const nov = this.subNovedades[i];
        ids_nov.push(nov.numeracion);
      }
      this.filtros.subnovedades = ids_nov;
    },

    seleccionarEmpresa() {
      this.filtros.empresa_id = this.empresa ? this.empresa.id : null;
    },

    selectOrigenSitio() {
      this.filtros.origen_id = this.origen ? this.origen.id : null;
    },

    selectDestinoSitio() {
      this.filtros.destino_id = this.destino ? this.destino.id : null;
    },
  },

  mounted() {
    this.buscarEmpresas();
    this.buscarSitios();
    this.getRelevancias();
    this.getTiposNovedades();
  },
};
</script>

<style></style>
