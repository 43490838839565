<template>
  <div class="hold-transition">
    <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 650px">
      <gmap-cluster
        :gridSize="30"
        :zoomOnClick="true"
        :enableRetinaIcons="true"
        :minimumClusterSize="10"
        ref="cluster"
        :imagePath="'/img/map_cluster/m'"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="m.icon"
          :animation="2"
          @click="toggleInfoWindow(m, index, m.info)"
        ></gmap-marker>
      </gmap-cluster>

      <gmap-cluster
        :gridSize="30"
        :zoomOnClick="true"
        :enableRetinaIcons="true"
        :minimumClusterSize="10"
        ref="cluster"
        :imagePath="'/img/map_cluster/m'"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markersEmerg"
          :position="m.position"
          :icon="m.icon"
          :animation="2"
          @click="toggleInfoWindow(m, index, m.info)"
        ></gmap-marker>
      </gmap-cluster>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
      <!--  <gmap-polygon
        v-for="poligono in poligonos"
        :options="{ fillColor: poligono.color }"
        :key="poligono.id"
        :paths="poligono.path"
        :editable="false"
        :draggable="false"
        @click="toggleInfoWindowPoli(poligono, poligono.id, poligono.info)"
      />
      <gmap-circle
        v-for="circular in circunferencias"
        :key="circular.id"
        :center="circular.center"
        :radius="circular.radius"
        :info="circular.info"
        @click="toggleInfoWindowCir(circular, circular.id, circular.info)"
      ></gmap-circle> -->
    </gmap-map>
  </div>
</template>

<script>
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import axios from "axios";
export default {
  name: "ReporteNovedadesHSEMapa",
  components: {
    GmapCluster,
  },

  data() {
    return {
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      center: { lat: 4.651371, lng: -74.071509 },
      zoom: 6,
      markers: [],
      markersEmerg: [],
      markersTraza: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
    };
  },

  methods: {
    async getNovedades() {
      let filtros = this.$parent.filtros;
      if (
        (filtros.fecha_ini_novedad && filtros.fecha_fin_novedad) ||
        (filtros.fecha_ini_guia && filtros.fecha_fin_guia)
      ) {
        this.$parent.cargando = true;
        let me = this;
        await axios
          .get("/api/hidrocarburos/reportenovedades/Mapa", {
            params: me.$parent.filtros,
          })
          .then(async function(response) {
            me.markers = [];
            me.markersEmerg = [];

            const datosNovedades = response.data.advertencia;
            await datosNovedades.forEach((nov) => {
              if (nov.latitud != null && nov.longitud != null) {
                let ventana =
                  '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
                  nov.vehiculo.placa +
                  "</h3><hr><b>Fecha novedad: </b> " +
                  nov.fecha_novedad +
                  "<br> <b>Viaje: </b>" +
                  nov.viaje_id +
                  "<br> <b>Transportadora: </b>" +
                  (nov.viaje.transportadora
                    ? nov.viaje.transportadora.razon_social
                    : "Sin asignar") +
                  "<br> <b>Posicion: </b>" +
                  nov.latitud +
                  ", " +
                  nov.longitud +
                  "<br> <b>Velocidad: </b> " +
                  nov.velocidad +
                  " Km/h" +
                  "<br> <b>Novedad: </b>" +
                  nov.nTipoNovedad +
                  " - " +
                  nov.nSubNovedad;
                const marker = {
                  lat: parseFloat(nov.latitud),
                  lng: parseFloat(nov.longitud),
                };
                me.markers.push({
                  position: marker,
                  icon: nov.icon,
                  info: ventana,
                  center: {
                    marker,
                  },
                });
              }
            });

            const datosNovedadesEmerg = response.data.emergencia;
            await datosNovedadesEmerg.forEach((nov) => {
              if (nov.latitud != null && nov.longitud != null) {
                let ventana =
                  '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
                  nov.vehiculo.placa +
                  "</h3><hr><b>Fecha novedad: </b> " +
                  nov.fecha_novedad +
                  "<br> <b>Viaje: </b>" +
                  nov.viaje_id +
                  "<br> <b>Transportadora: </b>" +
                  (nov.viaje.transportadora
                    ? nov.viaje.transportadora.razon_social
                    : "Sin asignar") +
                  "<br> <b>Posicion: </b>" +
                  nov.latitud +
                  ", " +
                  nov.longitud +
                  "<br> <b>Velocidad: </b> " +
                  nov.velocidad +
                  " Km/h" +
                  "<br> <b>Novedad: </b>" +
                  nov.nTipoNovedad +
                  " - " +
                  nov.nSubNovedad;
                const marker = {
                  lat: parseFloat(nov.latitud),
                  lng: parseFloat(nov.longitud),
                };
                me.markersEmerg.push({
                  position: marker,
                  icon: nov.icon,
                  info: ventana,
                  center: {
                    marker,
                  },
                });
              }
            });

            me.$parent.cargando = false;
          })
          .catch(function(error) {
            me.$parent.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Se debe seleccionar un rango de fechas",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return true;
      }
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getTipoNovedad(tipo_novedad) {
      let ntipoNovedad =
        tipo_novedad == 1
          ? "GPS"
          : tipo_novedad == 2
          ? "CALCULO RUTAS"
          : tipo_novedad == 3
          ? "INSPECCIONES"
          : "GEOCERCAS";

      return ntipoNovedad;
    },

    getTipoSubNovedad(tipo_subnovedad) {
      let ntipoSubnovedad =
        tipo_subnovedad == 5
          ? "Exceso de velocidad"
          : tipo_subnovedad == 7
          ? "Aceleración Brusca"
          : tipo_subnovedad == 8
          ? "Frenada Brusca"
          : tipo_subnovedad == 9
          ? "Curva Brusca"
          : tipo_subnovedad == 10
          ? "Maltrato Suspension"
          : tipo_subnovedad == 11
          ? "Alerta Impacto"
          : tipo_subnovedad == 12
          ? "Botón Emergencia"
          : "";
      return ntipoSubnovedad;
    },
  },
  mounted() {
    //this.getNovedades();
  },
};
</script>
