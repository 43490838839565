<template>
  <div class="card">
    <div class="card-body">
      <!-- filtros -->
      <div class="row">
        <div class="col-md-12">
          <pivot-table-component
            v-if="pivotData"
            :pivotData="pivotData"
            :aggregator-name="aggregatorName"
            :renderer-name="rendererName"
            class="table-responsive pb-4 pr-4"
          >
          </pivot-table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ReporteNovedadesHSEDinamico",
  components: {
    PivotTableComponent,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      guia: [],

      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      /*       filtros: {
        n_guia: null,
        producto_liquido_id: null,
        cedula_conductor: null,
        transportadora_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        placa: null,
      }, */
      listasForms: {
        empresas: [],
      },
    };
  },
  validations: {
    form: {},
  },
  methods: {
    async generarPivotTable() {
      let filtros = this.$parent.filtros;
      if (
        (filtros.fecha_ini_novedad && filtros.fecha_fin_novedad) ||
        (filtros.fecha_ini_guia && filtros.fecha_fin_guia)
      ) {
        this.$parent.cargando = true;
        let me = this;
        await axios
          .get("/api/hidrocarburos/reportenovedades/generalPivotTable", {
            params: filtros,
          })
          .then((response) => {
            me.$parent.cargando = false;
            me.pivotData = response.data;
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Se debe seleccionar un rango de fechas",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return true;
      }
    },
  },
  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
